import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "Common/BreadCrumb";
import Dropzone from "react-dropzone";
import Axios, { AxiosResponse } from "axios";
import Select from "react-select";
import { PatternFormat } from "react-number-format";
import {
  Card,
  Dropdown,
  Pagination,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
  ProgressBar,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Overlay,
  Button,
  Toast,
  Alert,
  Modal,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  searchHomeOwner,
  getHomeOwnerById,
  sendDocToS3,
  createProject,
  updateProject,
  getSingleProjectById,
  createHomeowner,
} from "../../../../slices/thunk";
import { useSelector, useDispatch } from "react-redux";
//Import Images
import successImg from "../../../../assets/images/success-img.png";
import { jwtDecode } from "jwt-decode";
import { useFormik } from "formik";
import * as Yup from "yup";

interface ProjectDetailsProps {
  projectDetails: any;
  onCreateProject: Function;
  disable: any;
}

interface ResponseData {
  message: string;
  result: any;
}
interface JwtPayload {
  userType: string;
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  projectDetails,
  onCreateProject,
  disable,
}) => {
  const location = useLocation();

  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error("Token is null");
  }

  const dispatch: any = useDispatch();
  const contractors = useSelector(
    (state: any) => state.Proposal.searchHomeowners
  );
  const errorApi = useSelector((state: any) => state.UserProfile.error);
  const homeOwnerD = useSelector((state: any) => state.Proposal.homeOwnerData);
  const [addagent, setAddagent] = useState<boolean>(false);

  const blueprintDoc = useSelector((state: any) => state.Proposal.blueprint);

  let project: any = null;
  project = useSelector((state: any) => state.Contract.project);

  const [formData, setFormData] = useState({
    projectName: project && project.p_name ? project.p_name : "",
    country: project && project.p_country ? project.p_country : "",
    address:
      project && project.p_street_address ? project.p_street_address : "",
    city: project && project.p_city ? project.p_city : "",
    state: project && project.p_state ? project.p_state : "",
    zip: project && project.p_zip ? project.p_zip : "",
    firstName:
      project && project.home_owner && project.home_owner.user_first_name
        ? project.home_owner.user_first_name
        : "",
    lastName:
      project && project.home_owner && project.home_owner.user_last_name
        ? project.home_owner.user_last_name
        : "",
    email:
      project && project.home_owner && project.home_owner.user_email
        ? project.home_owner.user_email
        : "",
    phone:
      project && project.home_owner && project.home_owner.user_phone
        ? project.home_owner.user_phone
        : "",
    costOfProject: project && project.p_cost ? project.p_cost : "",
    escrow: project && project.p_escrow == 1 ? true : false,
  });
  const [showHomeownerForm, setShowHomeownerForm] = useState<boolean>(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [newHomeowner, setNewHomeowner] = useState<any>([]);
  const [homeOwnerId, setHomeOwnerId] = useState<any>("");
  const [projectId, setProjectId] = useState<any>("");
  const [zipCodeError, setZipCodeError] = useState<any>("");
  const [newErrorApi, setNewErrorApi] = useState("");
  const [loading, setLoading] = useState(false);
  const [bluePrintUrl, setBluePrintUrl] = useState<any>(
    project && project.p_blue_print ? project.p_blue_print : ""
  );
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  useEffect(() => {
    if (project && project.home_owner) {
      setShowHomeownerForm(true);
      setHomeOwnerId(project.home_owner.user_id);
    }
  }, [project]);

  // if (errorApi && errorApi === "Request failed with status code 400") {
  //   setNewErrorApi("");
  // } else {
  //   setNewErrorApi("");
  // }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (name === "zip" && value.length === 5) {
      getData(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const [pathName, setPathName] = useState(null);

  useEffect(() => {
    if (!location.search) {
      return;
    }

    // Remove the initial "?" from location.search and split by the first "?"
    const queryString = location.search.substring(1);
    const [mainQueryString, nestedQueryString] = queryString.split("?");
    // Parse the main query parameters
    const mainParams: any = new URLSearchParams(mainQueryString);

    // Check if there's a nested query string to parse
    let nestedParams: any = null;
    if (nestedQueryString) {
      nestedParams = new URLSearchParams(nestedQueryString);
    }
    const id = nestedParams ? nestedParams.get("id") : null;
    setProjectId(id);
  }, [location.search]);

  const validateProjectDetails = () => {
    const { projectName, country, address, state, zip } = formData;
    if (!projectName || !country || !address || !state || !zip) {
      alert("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  //
  useEffect(() => {
    if (notification && notification.status) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);

      // Cleanup the timeout if the component unmounts or notification changes
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleAlertClick = () => {
    setNotification(null);
  };
  //

  const getData = (zip: any) => {
    setLoading(true);
    Axios.get(`https://api.api-ninjas.com/v1/zipcode?zip=${zip}`, {
      headers: { "X-Api-Key": "/aUUxj0+f0kHItNuddd6FQ==Piwjdaf3BFDlpgZo" },
    })
      .then((resp: any) => {
        if (resp && resp.length) {
          setFormData((prevState) => ({
            ...prevState,
            city: resp[0].city,
            state: resp[0].state,
            country: resp[0].country,
          }));
        } else {
          setZipCodeError("Zip Code not found");
          setNotification({
            status: true,
            message: "Zip Code not found",
            variant: "danger",
          });
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleSearch = () => {
    // Logic to search for the subcontractor
    // If found, setSubContData and set setShowForm to true
    // If not found, handle accordingly
  };
  const handleSelectedValue = async (e: any) => {
    setLoading(true);
    setHomeOwnerId(e);
    try {
      await dispatch(getHomeOwnerById(e));
      setShowHomeownerForm(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching homeowner data:", error);
    }
  };

  const handleAcceptedFiles = (files: any) => {
    setLoading(true);
    files.forEach((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    dispatch(sendDocToS3("blueprint", files[0]))
      .then((uploadedUrl: any) => {
        if (uploadedUrl) {
          setBluePrintUrl(uploadedUrl);
          setNotification({
            status: true,
            message: "Blueprint Uploaded Successfully",
            variant: "success",
          });
        } else {
          setNotification({
            status: true,
            message: "Blueprint Upload Failed",
            variant: "danger",
          });
        }
      })
      .catch((error: any) => {
        console.error("Error uploading file:", error);
        setNotification({
          status: true,
          message: "Blueprint Upload Failed",
          variant: "danger",
        });
      })
      .finally(() => {
        setselectedFiles(files);
        setLoading(false);
      });
  };

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function searchHomeowners(e: any) {
    dispatch(searchHomeOwner(e));
    // setShowHomeownerForm(true)
  }

  useEffect(() => {
    if (contractors.length) {
      const newData = contractors.map((item: any) => {
        return { label: item.user_email, value: item.id };
      });
      setNewHomeowner(newData);
    }
  }, [contractors]);

  useEffect(() => {
    if (homeOwnerD.length) {
      setFormData((prevState) => ({
        ...prevState,
        firstName: homeOwnerD[0].user_first_name,
        lastName: homeOwnerD[0].user_last_name,
        email: homeOwnerD[0].user_email,
        phone: homeOwnerD[0].user_phone,
      }));
    }
  }, [homeOwnerD]);

  // useEffect(() => {
  //   if (blueprintDoc !== "") {
  //     setBluePrintUrl(blueprintDoc);
  //   }
  // }, [blueprintDoc]);

  const handleCreateProject = () => {
    setLoading(true);
    let projectDetails = {
      projectName: formData.projectName,
      projectStatus: "DRAFT",
      projectHomeOwnerId: homeOwnerId,
      projectContractorId: decoded.userId,
      projectCountry: formData.country,
      projectStreetAddress: formData.address,
      city: formData.city,
      state: formData.state,
      zip: formData.zip,
      cost: formData.costOfProject,
      escrow: formData.escrow,
      bluePrint: bluePrintUrl,
      userId: decoded.userId,
    };
    dispatch(createProject(projectDetails))
      .then((resp: any) => {
        onCreateProject(2, resp.result.insertId);
        setLoading(false);
        setNotification({
          status: true,
          message: "Project Created Successfully",
          variant: "success",
        });
      })
      .catch((error: any) => {
        console.error("Error creating project:", error);
      });

    setLoading(false);
  };

  const handleUpdateProject = () => {
    setLoading(true);
    let projectDetails = {
      projectName: formData.projectName,
      projectStatus: "DRAFT",
      projectHomeOwnerId: homeOwnerId,
      projectContractorId: decoded.userId,
      projectCountry: formData.country,
      projectStreetAddress: formData.address,
      city: formData.city,
      state: formData.state,
      zip: formData.zip,
      cost: formData.costOfProject,
      escrow: formData.escrow,
      bluePrint: bluePrintUrl,
      userId: decoded.userId,
      projectId: projectId,
    };
    dispatch(updateProject(projectDetails))
      .then((resp: any) => {
        onCreateProject(2, projectId);
        setLoading(false);
        setNotification({
          status: true,
          message: "Project Updated Successfully",
          variant: "success",
        });
      })
      .catch((error: any) => {
        console.error("Error creating project:", error);
      });

    setLoading(false);
  };

  //add agent modal
  const handleShowAgent = () => setAddagent(true);
  const handleCloseAgent = () => {
    formik.resetForm();
    setAddagent(false);
  };

  const formik: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your Homeowner Name"),
      lastName: Yup.string().required("Please Enter Your Homeowner Last Name"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Homeowner Email"),
      contact: Yup.string().required("Please Enter Your Homeowner contact"),
    }),
    onSubmit: (values: any) => {
      setLoading(true);
      dispatch(createHomeowner(values))
        .then((resp: any) => {
          setNotification({
            status: true,
            message: "Homeowner Created Successfully",
            variant: "success",
          });
          setLoading(false);
          setAddagent(false);
        })
        .catch((err: any) => {
          setNewErrorApi("User Already Exist");
          setLoading(false);
          setAddagent(true);
        });

      formik.resetForm();
    },
  });
  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading && (
        <div className="custom-overlay show">
          <Spinner animation="border" role="status" className="custom-spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Card>
        <Card.Body>
          <h5 className="card-title mb-3">Project name and address</h5>

          <Row className="g-3 align-items-center mb-3 mb-3">
            <Col lg={6}>
              <div>
                <Form.Label htmlFor="projectName">
                  Project Name
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {" "}
                        Please enter your project name here.{" "}
                      </Tooltip>
                    }
                  >
                    <Button className="infoButton">?</Button>
                  </OverlayTrigger>
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="projectName"
                  name="projectName"
                  placeholder="Enter project name"
                  value={formData.projectName}
                  onChange={handleInputChange}
                  required
                  disabled={disable}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Form.Label htmlFor="zip">
                  Zip
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {" "}
                        Please enter Zip Code here and after entring system will
                        automatically fetch City, State and Country{" "}
                      </Tooltip>
                    }
                  >
                    <Button className="infoButton">?</Button>
                  </OverlayTrigger>
                  <span className="text-danger">*</span>
                  {zipCodeError != "" ? (
                    <span style={{ color: "red" }}>
                      <b> Zip Code not found</b>
                    </span>
                  ) : null}
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    id="zip"
                    name="zip"
                    placeholder="Enter Zip"
                    value={formData.zip}
                    onChange={handleInputChange}
                    required
                    disabled={disable}
                  />
                  {/* <Button variant="primary" onClick={getData}>
                    Submit
                  </Button> */}
                </InputGroup>
              </div>
            </Col>
          </Row>

          <Row className="g-3 align-items-center mb-3 mb-3">
            <Col lg={12}>
              <div>
                <Form.Label htmlFor="address">
                  Address
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {" "}
                        Please enter your Address here for which you want to
                        make contract.{" "}
                      </Tooltip>
                    }
                  >
                    <Button className="infoButton">?</Button>
                  </OverlayTrigger>
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Enter Address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                  disabled={disable}
                />
              </div>
            </Col>
          </Row>
          <Row className="g-3 align-items-center mb-3 mb-3">
            <Col lg={6}>
              <div>
                <Form.Label htmlFor="city">
                  City
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="city"
                  disabled
                  placeholder="Enter City"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Form.Label htmlFor="state">
                  State
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="state"
                  disabled
                  name="state"
                  placeholder="Enter State"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row className="g-3 align-items-center mb-3 mb-3">
            <Col lg={6}>
              <div>
                <Form.Label htmlFor="country">
                  Country
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="country"
                  disabled
                  name="country"
                  placeholder="Enter Country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <h5 className="card-title mt-5">Homeowner details</h5>

          <Row className="g-3 align-items-center mb-3">
            <p>
              Search for a homeowner in our database using their email address.
              The homeowner must already have a Tradesmen account. Adding
              homeowner details now is not compulsory as it can be added to the
              project later
            </p>
            <Row>
              <Col xl={10}>
                <p>
                  <b>
                    If you can't find the homeowner details in the search
                    results, please Click on add homeowner button to add the
                    homeowner's information. Then, search again using the
                    homeowner's email address.
                  </b>
                </p>
              </Col>
              <Col xl={2}>
                <Button variant="secondary" onClick={handleShowAgent}>
                  <i className="bi bi-person-plus align-baseline me-1"></i> Add
                  Homeowner
                </Button>
              </Col>
            </Row>
            <Col lg={12}>
              <div>
                <Form.Label htmlFor="searchSubContractor">
                  Search By Email
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {" "}
                        Type Email of homeowner to get its details{" "}
                      </Tooltip>
                    }
                  >
                    <Button className="infoButton">?</Button>
                  </OverlayTrigger>
                </Form.Label>
                <Select
                  options={newHomeowner}
                  onInputChange={(e: any) => searchHomeowners(e)}
                  onChange={(selectedOption: any) => {
                    if (selectedOption && selectedOption.value) {
                      handleSelectedValue(selectedOption.value);
                    }
                  }}
                />
              </div>
            </Col>
          </Row>

          {showHomeownerForm ? (
            // Form for when subcontractor is found
            <React.Fragment>
              <p className="mb-5">
                These details are prefilled and can be edited only by a
                homeowner.
              </p>

              <Row className="g-3 align-items-center mb-3 mb-3">
                <Col lg={6}>
                  <div>
                    <Form.Label htmlFor="firstName">
                      First name
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="Enter First Name"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      required
                      disabled
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <Form.Label htmlFor="lastName">
                      Last name
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Enter Last Name"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      required
                      disabled
                    />
                  </div>
                </Col>
              </Row>

              <Row className="g-3 align-items-center">
                <Col lg={6}>
                  <div>
                    <Form.Label htmlFor="homeOwnerEmail">
                      Email address
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="homeOwnerEmail"
                      name="homeOwnerEmail"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      disabled
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <Form.Label htmlFor="homeOwnerPhone">
                      Phone
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="homeOwnerPhone"
                      name="homeOwnerPhone"
                      placeholder="Enter Homeowner Phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                      disabled
                    />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Row className="mb-3 mb-3">
            {bluePrintUrl !== "" ? (
              <Col lg="12">
                <img
                  src={bluePrintUrl}
                  alt="blueprint"
                  style={{ maxHeight: "300px", maxWidth: "500px" }}
                />
                <Button className="danger" onClick={() => setBluePrintUrl("")}>
                  Remove
                </Button>
              </Col>
            ) : (
              <Col lg="12">
                <div>
                  <h5 className="card-title mt-5">Homeowner details </h5>
                  <p>
                    If available, please upload any blueprints or documents
                    related to this project
                  </p>
                  <Dropzone
                    onDrop={(acceptedFiles: any) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps }: any) => (
                      <div
                        className="dropzone course-dropzone border border-1 border-dashed text-center"
                        {...getRootProps()}
                      >
                        <div className="dz-message needsclick">
                          <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                          </div>
                          <h4>Drop Blueprints here</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h5 className="card-title mt-5">Cost of project</h5>
          <p className="mb-2">
            Please enter the total cost of the project. Escrow payment is set my
            default, but you can change it
          </p>
          <Row className="g-3 align-items-center mb-3 mb-3">
            <Col lg={12}>
              <div>
                <Form.Label htmlFor="course-title-input">
                  Cost of project{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {" "}
                        Please enter estimated cost of your project.{" "}
                      </Tooltip>
                    }
                  >
                    <Button className="infoButton">?</Button>
                  </OverlayTrigger>
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="costOfProject"
                  name="costOfProject"
                  placeholder=""
                  value={formData.costOfProject}
                  onChange={handleInputChange}
                  required
                  disabled={disable}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Row className="g-3 align-items-center mb-3 mb-3">
            <Col lg={12}>
              <div>
                <Form.Label
                  htmlFor="course-title-input"
                  style={{ fontSize: "40px" }}
                >
                  Escrow
                  <span className="text-danger">*</span>
                  <span className="text-muted" style={{ fontSize: "16px" }}>
                    {" "}
                    Would you like to escrow this project? If yes, click the
                    button below.
                  </span>
                </Form.Label>
                <div className="form-check form-switch mb-3">
                  <Form.Check>
                    <Form.Check.Input
                      type="checkbox"
                      style={{ height: "50px", width: "110px" }}
                      role="switch"
                      id="escrow"
                      name="escrow"
                      onChange={handleInputChange}
                      checked={formData.escrow}
                      disabled={
                        disable || (project && project.p_escrow !== null)
                      }
                    />
                  </Form.Check>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="d-flex align-items-start gap-3 mt-4">
        <button
          type="button"
          className="btn btn-success btn-label right ms-auto nexttab nexttab"
          onClick={() =>
            projectId !== "" ? handleUpdateProject() : handleCreateProject()
          }
        >
          <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
          Go to Milstones
        </button>
      </div>

      {/* Add Homeowner */}
      <Modal show={addagent} onHide={handleCloseAgent}>
        <Modal.Header closeButton>
          <Modal.Title as="h5" id="addAgentModalLabel">
            Add Home Owner
          </Modal.Title>
        </Modal.Header>
        <Form
          className="tablelist-form"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body>
            <div className="mb-3">
              <Form.Label htmlFor="agent-name-input">
                Homeowner First Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="firstName"
                name="firstName"
                placeholder="Enter Homeowner First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.firstName}
              />
              {formik.errors.firstName && formik.touched.firstName ? (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Form.Label htmlFor="agent-name-input">
                Homeowner Last Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Enter Homeowner Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.lastName}
              />
              {formik.errors.lastName && formik.touched.lastName ? (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Form.Label htmlFor="email-input">
                Homeowner Email<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                name="email"
                placeholder="Homeowner Enter email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.email}
              />
              {formik.errors.email && formik.touched.email ? (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Form.Label htmlFor="contact-input">
                Homeowner Contact Number<span className="text-danger">*</span>
              </Form.Label>
              <PatternFormat
                className="form-control"
                displayType="input"
                id="contact"
                placeholder="Enter contact no"
                name="contact"
                format="##########"
                value={formik.values.contact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.errors.contact && formik.touched.contact ? (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {formik.errors.contact}
                </Form.Control.Feedback>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="btn btn-ghost-danger"
              onClick={handleCloseAgent}
            >
              <i className="bi bi-x-lg align-baseline me-1"></i> Close{" "}
            </Button>
            <Button type="submit" variant="primary" id="add-btn">
              {"Add"}
            </Button>
            <p style={{ width: "100%" }}>
              {newErrorApi !== "" ? (
                <span style={{ color: "red", width: "100%" }}>
                  <b>User already exist with this email</b>
                </span>
              ) : (
                ""
              )}
            </p>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ProjectDetails;
