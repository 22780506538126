import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  subcontractors: [],
  singleSubcontractor:null,
  error: null, // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const  subcontractorSlice = createSlice({
  name: "subcontractors",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    subcontractorSuccess(state, action) { 
       state.subcontractors = action.payload.result
      state.loading = false;
      state.errorMsg = false;
    },
    singleSubcontractorSuccess(state, action) { 
       state.singleSubcontractor = action.payload.subcontractor
      state.loading = false;
      state.errorMsg = false;
    },
    clearSingleSubcontractor(state) {
      state.singleSubcontractor = null;
    },
  },
});

export const {
  apiError,
  subcontractorSuccess,
  singleSubcontractorSuccess,
  clearSingleSubcontractor
} = subcontractorSlice.actions

export default subcontractorSlice.reducer;