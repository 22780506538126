import React from "react";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

interface LeadDetailsPro {
  data: any;
}

const Leadsdetails: React.FC<LeadDetailsPro> = ({ data }) => {
  const appliedLeads = useSelector((state: any) => state.Leads.appliedLeads);
  return (
    <React.Fragment>
      <Col xl={9} lg={8}>
        <Row>
          {appliedLeads.length > 0 ? (
            appliedLeads.map((item: any, key: any) => {
              const createdAtDate = new Date(item.lead_created_at);
              const leadBudget = item.lead_budget
                ? Number(item.lead_budget).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                : "N/A";

              // Format date and time
              const formattedDate = createdAtDate.toLocaleDateString();
              const formattedTime = createdAtDate.toLocaleTimeString();
              return (
                <Link to={`/project-overview?id=${item.id}`}>
                  <Col lg={12} key={item.id}>
                    <Card className="overflow-hidden">
                      <Row className="g-0">
                        <Col xl={3} md={4}>
                          <Card.Body className="bg-info-subtle text-center learning-widgets d-flex align-items-center justify-content-center h-100">
                            <img
                              src={
                                "https://res.cloudinary.com/dr6rh7hon/image/upload/v1719393122/1912.i121.048.P.m005.c33.isometric_marketing_strategy_business_set-09_x5w7d4.jpg"
                              }
                              alt=""
                              className="avatar-lg"
                            />
                            <img
                              src={
                                "https://res.cloudinary.com/dr6rh7hon/image/upload/v1719393122/1912.i121.048.P.m005.c33.isometric_marketing_strategy_business_set-09_x5w7d4.jpg"
                              }
                              alt=""
                              className="effect"
                            />
                          </Card.Body>
                        </Col>
                        <Col xl={9} md={8}>
                          <Card.Body>
                            <div className="d-flex mb-3 align-items-center">
                              <div className="flex-grow-1">
                                <span
                                  className={`badge bg-success-subtle text-success ml-5`}
                                >
                                  {item.lead_status !== null
                                    ? item.lead_job_status
                                    : "Pending Request"}
                                </span>
                              </div>
                              <div className="flex-shrink-0 fs-lg">
                                <p>
                                  Homeowner's budget for this Job is{" "}
                                  <b>{leadBudget}</b>
                                </p>
                              </div>
                            </div>
                            <h5 className="text-truncate text-capitalize">
                              <Link
                                to={`/project-overview?id=${item.id}`}
                                className="text-reset"
                              >
                                {" "}
                                {item.lead_cat_id}
                              </Link>
                            </h5>
                            <p className="text-muted">{item.user_email}</p>
                            <p>{item.lead_description}</p>
                            <ul className="d-flex align-items-center gap-2 flex-wrap list-unstyled mb-0">
                              <li>
                                <p className="text-muted mb-0">
                                  <i className="ph ph-calender align-baseline text-primary me-1"></i>{" "}
                                  {formattedDate}
                                </p>
                              </li>
                              <li>
                                <p className="text-muted mb-0">
                                  <i className="ph bi-clock  align-middle text-primary me-1"></i>{" "}
                                  {formattedTime}
                                </p>
                              </li>
                              <li>
                                <p className="text-muted mb-0">
                                  <i className="ph ph-money align-middle text-primary me-1"></i>
                                  0
                                </p>
                              </li>
                              <li>
                                <p className="text-muted mb-0">
                                  <i className="ph ph-globe-stand align-middle text-primary me-1"></i>{" "}
                                  United States
                                </p>
                              </li>
                            </ul>
                          </Card.Body>
                          <Card.Body className="border-top border-dashed hstack align-items-center gap-2">
                            <div className="d-flex align-items-center gap-2 flex-grow-1 position-relative">
                              <div className="flex-shrink-0">
                                <img
                                  src={
                                    "https://themes.themesbrand.com/steex/react/material/static/media/avatar-1.8f473ed0f0cfa8c1df8a.jpg"
                                  }
                                  alt=""
                                  className="avatar-xxs rounded-circle"
                                />
                              </div>
                              <h6 className="flex-grow-1 mb-0">
                                <Link
                                  to="#"
                                  className="text-reset stretched-link"
                                >
                                  {item.user_first_name} {item.user_last_name}{" "}
                                </Link>
                              </h6>
                            </div>
                            <div className="flex-shrink-0">
                              <span className="badge bg-warning-subtle text-warning">
                                <i className="bi bi-star-fill align-baseline me-1"></i>{" "}
                                4.5
                              </span>
                            </div>
                          </Card.Body>
                          <div>
                            <ProgressBar
                              now={74}
                              variant="primary"
                              className="progress-sm rounded-0"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Link>
              );
            })
          ) : (
            <Col lg={12}>
              <Card className="overflow-hidden">
                <Row className="g-0">
                  <Col xl={12} md={12}>
                    <Card.Body className="bg-info-subtle text-center d-flex align-items-center justify-content-center">
                      <img
                        src={
                          "https://res.cloudinary.com/dr6rh7hon/image/upload/v1719393261/Wavy_Ppl-08_Single-02_zf3umz.jpg"
                        }
                        alt=""
                        style={{ height: "500px" }}
                      />
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default Leadsdetails;
